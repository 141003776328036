import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Projects = props => (
  <StaticQuery
    query={graphql`
      query {
        allProjectsJson {
          edges {
            node {
              cliente
              contribution
              durata
              evento
              luogo
              mese
              ospiti
              titolo
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    quality: 60
                    aspectRatio: 1.5
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout location="/projects" title="Projects">
          <Seo title="Projects" />
          {getProjects(data.allProjectsJson.edges)}
        </Layout>
      </>
    )}
  />
)

function getProjects(projects) {
  let objRow = []

  Object.keys(projects).forEach(function (val, index) {
    let project = projects[val].node
    objRow.push(getCol(project, index))
  })

  return (
    <div className="bg-white flex flex-wrap -mx-1 px-2 overflow-hidden">
      {objRow}
    </div>
  )

}

function getHashtag(contribution) {
  let objReturn = []
  Object.keys(contribution).forEach(function (val, index) {
    var re = / /gi
    let sTag = contribution[index].toString()
    sTag = sTag.toLowerCase()
    sTag = sTag.replace(re, "_")

    objReturn.push(
      <span
        key={"hash_" + index.toString()}
        className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
      >
        #{sTag}
      </span>
    )
  })
  return objReturn
}

function getCol(project, index) {
  let sDays = "days"
  let sClassName = "my-2 px-2 w-full overflow-hidden md:w-1/2 lg:w-1/3 xl:w-1/4"
  if (project.durata === "1") {
    sDays = "day"
    sClassName = "animate__animated animate__heartBeat " + sClassName
  }

  return (
    <div
      key={"project_" + index.toString()}
      className = {sClassName}
    >
      <GatsbyImage
        imgClassName="rounded-xl"
        imgStyle={{
          WebkitBorderRadius: "20px",
          MozBorderRadius: "20px",
          borderRadius: "20px",
        }}
        loading="eager"
        image={getImage(project.image)}
        alt={project.evento}
        style={{ width: "100%", height: "0", paddingBottom: "55%" }}
      />
      <div className="py-4">
        <div className="font-bold text-xl mb-2">
          {project.cliente} {project.titolo}
        </div>
        <p className="text-gray-700 text-base bg-gray-50 p-2 rounded-xl">
          The project {project.cliente} {project.titolo}, it has been released for <b>{project.evento}</b>, we were involved in {project.luogo} for {project.durata} {sDays} in {get_Mese(project.mese)}, number of guests {project.ospiti}
        </p>
      </div>
      <div className="text-gray-700 font-bold text-base">Our contribution:</div>
      <div>{getHashtag(project.contribution)}</div>
    </div>
  )
}

function get_Mese(mese) {
  let aMonth = "January,February,March,April,Maj,June,July,August,September,October,November,December".split(",")
  let aWk = mese.split("-")
  let sYear = aWk[0]
  let sMonth = aMonth[parseInt(aWk[1]) - 1]

  return (sMonth + " " + sYear)
}

export default Projects